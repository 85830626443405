import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import * as mockCopy from 'src/mock/copy'
import { typography, colors, util, mq } from 'src/styles'
import ContentfulRichText from 'src/components/ContentfulRichText'

const ContentWrapper = styled.div`
	${ typography.responsiveStyles('padding-top', 91, 51, 33, 26) }
	${ typography.responsiveStyles('padding-bottom', 91, 51, 33, 26) }
`

const Quote = styled.div`
	position: relative;
	${ mq.mediumAndBelow } {
		padding-left: 40px;
	}
	p {
		margin-top: 1.5em;
		${ typography.bodyMedium }
	}
	&:before {
		display: inline-block;
		content: '“';
		position: absolute;
		right: calc(100% + .175em);
		top: -.035em;
		${ typography.h4 }
		${ util.responsiveStyles('font-size', 126, 98, 78, 66) }
		color: ${ ({ accent }) => accent ? accent : colors.textColor };
		${ mq.mediumAndBelow } {
			right: auto;
			left: -.05em;
		}
	}
`

const CalloutText = ({
	id,
	theme,
	prevTheme,
	nextTheme,
	headlineSize,
	eyebrow,
	headline,
	text,
	buttons,
	cards,
	icon,
	alignment,
	customTheme,
	type,
	pageAccent,
	lastItem,
	prevFullWidth,
	nextFullWidth,
	index
}) => {
	return (
		<Section
			settheme={theme}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			sectionid="CenteredAlignedText"
			sectionTheme={type === 'projectQuote' ? 'transparent' : customTheme || theme}
			lastItem={lastItem}
			firstItem={index === 0}
			prevFullWidth={prevFullWidth}
			nextFullWidth={nextFullWidth}
		>
			<ContentWrapper>
				<Grid
					small="1 [12] 1"
					medium={type === 'projectQuote' ? "2 [10] 2" : "1 [12] 1"}
					large={type === 'projectQuote' ? "3 [8] 3" : "1 [12] 1"}
				>
					{type === 'projectQuote' ? (
						<Quote accent={pageAccent}>
							<ContentfulRichText richText={text.json}/>
						</Quote>
					) : (
						<TextLockup
							theme={theme}
							eyebrow={eyebrow}
							headline={headline}
							headlineSize={headlineSize}
							text={text}
							icon={icon}
							buttons={buttons}
							alignment={alignment}
						/>
					)}
				</Grid>
			</ContentWrapper>
		</Section>
	)
}

CalloutText.defaultProps = {
	headlineSize: 'h3',
	type: 'default'
}

export default CalloutText
