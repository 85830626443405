import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Image from 'src/components/Image'
import Caption from 'src/components/Caption'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ConditionalRender from 'src/components/ConditionalRender'
import { mq, util } from 'src/styles'

const ImageWrap = styled(ScrollEntrance)`
	margin: 0 auto;
	${ ({ type }) => type === 'normal' ? `
		max-width: 100%;
	` : `` }
	${ ({ type }) => type === 'web' ? `
		max-width: 100%;
	` : `` }
	${ ({ type }) => type === 'mobile' ? `
		${ util.responsiveStyles('width', 400, 300, 300, 250) }
		max-width: 90%;
	` : `` }
`

const MultipleImages = ({
	theme,
	prevTheme,
	nextTheme,
	images,
	simpleImages,
	lastItem,
	prevFullWidth,
	nextFullWidth,
	type
}) => {
	let imageCount = 2

	let grid = {
		small: '[1]',
		medium: '[1] [1]',
		large: '[1] [1]',
		sizes: '(max-width: ' + mq.mediumBreakpoint + 'px) 100vw, 50vw'
	}

	if (images) {
		imageCount = images.length
	} else if (simpleImages) {
		imageCount = simpleImages.length
	}

	if (imageCount === 3) {
		grid = {
			small: '[1]',
			medium: '[1] [1] [1]',
			large: '[1] [1] [1]',
			sizes: '(max-width: ' + mq.mediumBreakpoint + 'px) 100vw, 33.333vw'
		}
	}

	if (imageCount === 4) {
		grid = {
			small: '[1]',
			medium: '[1] [1]',
			large: '[1] [1] [1] [1]',
			sizes: '(max-width: ' + mq.mediumBreakpoint + 'px) 100vw, 25vw'
		}
	}

	return (
		<Section
			settheme={theme}
			nextTheme={nextTheme}
			prevTheme={prevTheme}
			lastItem={lastItem}
			prevFullWidth={prevFullWidth}
			nextFullWidth={nextFullWidth}
		>
			<Grid small={type === 'edgeToEdge' ? '[1]' : '1 [12] 1'}>
				<Grid
					small={grid.small}
					medium={grid.medium}
					large={grid.large}
					colGap={type === 'edgeToEdge' ? 0 : [ ['3vw', '2.5vw', '2vw'] ]}
					rowGap={type === 'edgeToEdge' ? 0 : [ '80px', '8vw', '8vw' ]}
				>
					{images && images.map(({ id, caption, ...image }, index) => (
						<div key={id + '_' + index}>
							<ImageWrap delay={index} type={image.type || 'normal'}>
								<div>
									<Image image={image.image} type={image.type || 'normal'} sizes={grid.sizes}/>
								</div>
								{caption && (
									<Caption>{caption}</Caption>
								)}
							</ImageWrap>
						</div>
					))}

					{simpleImages && simpleImages.map((image, index) => (
						<div key={image.id + '_' + index}>
							<ImageWrap delay={index} type={type || 'normal'}>
								<div>
									<Image image={image} type={type || 'normal'} sizes={grid.sizes}/>
								</div>
							</ImageWrap>
						</div>
					))}
				</Grid>
			</Grid>
		</Section>
	)
}

export default MultipleImages
