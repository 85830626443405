import React from 'react'

import ATF from 'src/components/ATF'
import CalloutText from 'src/components/CalloutText'
import FiftyFifty from 'src/components/FiftyFifty'
import RichTextSection from 'src/components/RichTextSection'
import WideMedia from 'src/components/WideMedia'
import MultipleImages from 'src/components/MultipleImages'
import ProjectGrid from 'src/components/ProjectGrid'
import ProcessSection from 'src/components/ProcessSection'

const componentMap = {
	ContentfulAboveTheFold: ATF,
	ContentfulCalloutText: CalloutText,
	ContentfulFiftyFifty: FiftyFifty,
	ContentfulRichTextSection: RichTextSection,
	ContentfulWideMedia: WideMedia,
	ContentfulMultipleImages: MultipleImages,
	ContentfulProjectGrid: ProjectGrid,
	ContentfulProcessSection: ProcessSection
}

export default ({
		title,
		item,
		prevTheme,
		nextTheme,
		index,
		pageAccent,
		lastItem,
		prevFullWidth,
		nextFullWidth,
		pageId,
		pageType,
		tagline
	}) => {
	const Component = componentMap[item.__typename]

	return Component ? (
		<Component {...item}
			title={title}
			pageAccent={pageAccent}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			prevFullWidth={prevFullWidth}
			nextFullWidth={nextFullWidth}
			index={index}
			lastItem={lastItem}
			pageId={pageId}
			pageType={pageType}
			tagline={tagline}
		/>
	) : null
}
