import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import { colors, util, typography, animations, mq } from 'src/styles'
import Button from 'src/components/Button'
import { AiOutlineArrowRight } from 'react-icons/ai'
import Input from 'src/components/Input'
import { Transition } from 'react-transition-group'
import { MdClose, MdArrowForward } from 'react-icons/md'
import { validateEmail } from 'src/utils/validations'

const timeout = 700
const timingFunction = 'cubic-bezier(0.44, 0.24, 0.16, 1.00)'

const Wrapper = styled.form`
	position: fixed;
	z-index: 13;
	display: flex;
	flex-direction: column;
	top: 0;
	right: 0;
	bottom: 0;
	background: ${ colors.blue };
	color: ${ colors.bgColor };
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	${ util.responsiveStyles('width', 800, 600, 500, 500) }
	max-width: 100%;
	transition: transform ${ timeout }ms ${ timingFunction };
	transform: translate3d(110%, 0, 0);
	${ ({ transitionStatus }) => transitionStatus === 'entering' && `
		transform: translate3d(110%, 0, 0);
	` }
	${ ({ transitionStatus }) => transitionStatus === 'entered' && `
		transform: none;
	` }
`

const DrawerHeader = styled.div`
	${ util.responsiveStyles('padding-top', 60, 48, 40, 30) }
	${ util.responsiveStyles('padding-left', 60, 50, 40, 26) }
	${ util.responsiveStyles('padding-right', 60, 50, 40, 26) }
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	align-items: flex-start;
	z-index: 2;
	h3 {
		flex-grow: 1;
		margin: 0;
		padding-right: 20px;
		${ typography.h2 }
	}
`
const DrawerContent = styled.div`
	${ util.responsiveStyles('padding', 60, 50, 40, 26) }
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`

const DrawerFooter = styled.div`
	${ util.responsiveStyles('padding-bottom', 60, 50, 40, 26) }
	${ util.responsiveStyles('padding-left', 60, 50, 40, 26) }
	${ util.responsiveStyles('padding-right', 60, 50, 40, 26) }
	padding-top: 0;
	flex-grow: 0;
	flex-shrink: 0;
	z-index: 2;
`

const CloseButton = styled(Button)`
	color: ${ colors.bgColor };
	background: transparent;
	margin-right: -12px;
	svg {
		transition: transform ${ animations.mediumSpeed } ease-in-out;
	}
	&:hover,
	&:focus,
	&:active {
		background: transparent;
		color: ${ colors.bgColor };
		${ mq.mediumAndUp } {
			color: ${ colors.blue };
			background: ${ colors.bgColor };
			svg {
				transform: scale(.666);
			}
		}
	}
`

const SubmitButton = styled(Button)`
	display: block;
	width: 100%;
	padding-bottom: 3px;
	background: transparent;
	border: 2px solid ${ colors.bgColor };
	color: ${ colors.bgColor };
	padding-left: 1.5em;
	padding-right: 1.25em;
	> div {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	svg {
		margin-bottom: -2px;
	}
	&:hover {
		background: ${ colors.bgColor };
		color: ${ colors.blue };
	}
	&[disabled],
	&:disabled,
	&[disabled]:hover,
	&:disabled:hover,
	&[disabled]:focus,
	&:disabled:focus,
	&[disabled]:active,
	&:disabled:active {
		opacity: .5;
		background: transparent;
		color: ${ colors.bgColor };
		border-color: ${ colors.bgColor };
	}
`

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	z-index: 12;
	background: ${ rgba(colors.bgColor, 0.9) };
	transition: opacity ${ timeout }ms ${ timingFunction };
	opacity: 0;
	${ ({ transitionStatus }) => transitionStatus === 'entering' && `
		opacity: 0;
	` }
	${ ({ transitionStatus }) => transitionStatus === 'entered' && `
		opacity: 1;
	` }
`

const ContactInputs = styled.div`
`

const UnderlinedInput = styled(Input)`
	${ util.responsiveStyles('margin-bottom', 25, 20, 16, 14) }
	input {
		padding-left: 0;
		padding-right: 0;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		height: 50px;
		background: transparent;
		color: ${ colors.bgColor };
		border-color: ${ colors.bgColor };
		&:focus, &:hover, &:active {
			background: transparent;
			border-color: ${ colors.bgColor };
		}
	}
	label {
		padding: 0;
		left: 0;
		margin-left: 0;
		padding-bottom: 8px;
		height: auto;
		bottom: 0;
		top: auto;
		font-weight: 600;
		color: ${ colors.bgColor };
		opacity: .5;
		&.focused {
			opacity: 1;
			transform: translate3d(0, -18px, 0) scale(.75);
			color: ${ colors.bgColor };
		}
	}
`

class ContactDrawer extends React.Component {

	constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: '',
      name: '',
			company: '',
			_subject: '',
			email: '',
			message: ''
    };
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
        this.setState({
          status: '',
		      name: '',
					company: '',
					_subject: '',
					email: '',
					message: ''
        })
        form.reset()
        if (this.props.onSuccess) {
      		this.props.onSuccess()
        }
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }

	handleInput = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

	render () {
		const { closeDrawer, open } = this.props
		const { name, company, email, _subject, message } = this.state

		const isValid = name && validateEmail(email) && _subject && message

		return (
			<Transition
				in={open}
				timeout={{
					enter: 1,
					exit: timeout
				}}
				unmountOnExit
				mountOnEnter
			>
				{transitionStatus => (
					<Fragment>
					  <Wrapper
					  	transitionStatus={transitionStatus}
					  	onSubmit={this.submitForm}
			        action="https://formspree.io/xpzjdalr"
			        method="POST"
					  >
					  	<DrawerHeader>
					  		<h3>Contact Us</h3>
					  		<CloseButton
					  			onClick={closeDrawer}
					  			shape="circle"
					  			size="small"
					  			setTheme="transparent"
					  			icon={<MdClose size="30px"/>}
					  		/>
					  	</DrawerHeader>
					  	<DrawerContent>
					  		<ContactInputs>
						  		<UnderlinedInput
						  			settheme="bgColor"
						  			onChange={this.handleInput}
						  			label="Name"
						  			name="name"
						  			value={name}
						  		/>
						  		<UnderlinedInput
						  			settheme="bgColor"
						  			onChange={this.handleInput}
						  			label="Subject"
						  			name="_subject"
						  			value={_subject}
						  		/>
						  		<UnderlinedInput
						  			settheme="bgColor"
						  			onChange={this.handleInput}
						  			label="Company"
						  			name="company"
						  			value={company}
						  		/>
						  		<UnderlinedInput
						  			settheme="bgColor"
						  			onChange={this.handleInput}
						  			label="Email"
						  			name="email"
						  			value={email}
						  		/>
						  		<UnderlinedInput
						  			settheme="bgColor"
						  			onChange={this.handleInput}
						  			label="Message"
						  			name="message"
						  			value={message}
						  		/>
					  		</ContactInputs>
					  	</DrawerContent>
					  	<DrawerFooter>
					  		<SubmitButton
					  			iconPosition="right"
					  			size="large"
					  			settheme="bgColor"
					  			onClick={closeDrawer}
					  			icon={<MdArrowForward size="24px"/>}
					  			disabled={!isValid}
					  		>
					  			Send
					  		</SubmitButton>
					  	</DrawerFooter>
					  </Wrapper>
					  <Overlay transitionStatus={transitionStatus} onClick={closeDrawer}/>
				  </Fragment>
				 )}
			</Transition>
		)
	}
}

ContactDrawer.defaultProps = {
	closeDrawer: () => {}
}

export default ContactDrawer
