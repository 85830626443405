import React from 'react'
import styled from '@emotion/styled'
import ThemeSelector from 'src/components/ThemeSelector'
import Button from 'src/components/Button'
import ConditionalRender from 'src/components/ConditionalRender'
import { util } from 'src/styles'
import { InView } from 'react-intersection-observer'
import { withPageContext } from 'src/contexts/pageContext'

const SectionWrapper = styled.div`
	position: relative;
	z-index: ${ ({ zIndex }) => zIndex };
	${ ({ padded, prevTheme, nextTheme, settheme }) => padded !== false && `
		${ util.responsiveStyles('padding-top', 130, 75, 50, 40) }
		${ util.responsiveStyles('padding-bottom', 130, 75, 50, 40) }
	` }
	${ ({ lastItem }) => lastItem && `
		${ util.responsiveStyles('padding-bottom', 260, 150, 100, 80) }
	` }
	${ ({ firstItem }) => firstItem && `
		${ util.responsiveStyles('padding-top', 260, 150, 100, 80) }
	` }
	${ ({ prevFullWidth }) => prevFullWidth && `
		${ util.responsiveStyles('padding-top', 260, 150, 100, 80) }
	` }
	${ ({ nextFullWidth }) => nextFullWidth && `
		${ util.responsiveStyles('padding-bottom', 260, 150, 100, 80) }
	` }
`

class Section extends React.Component {
	updatePageBackground = (color) => {
		const pageBg = this.props.pageContext.pageBg
		if (color && pageBg !== color) {
			this.props.pageContext.updateState('pageBg', color)
		}
	}
	
	render () {
		const {
			className,
			children,
			settheme,
			sectionTheme,
			prevTheme,
			nextTheme,
			zIndex,
			buttons,
			sectionid,
			padded,
			threshold,
			lastItem,
			firstItem,
			prevFullWidth,
			nextFullWidth,
			id
		} = this.props

		let buttonColors = {
			textColor: 'bgColor',
			black: 'bgColor',
			mainColor: 'bgColor',
			bgColor: 'default'
		}

		let customTheme = false
		if (settheme && settheme.includes('#')) {
			customTheme = true
		}

		return (
			<SectionWrapper
				className={className}
				settheme={settheme}
				prevTheme={prevTheme}
				nextTheme={nextTheme}
				zIndex={zIndex}
				padded={padded}
				lastItem={lastItem}
				firstItem={firstItem}
				prevFullWidth={prevFullWidth}
				nextFullWidth={nextFullWidth}
				id={id}
			>
				<InView threshold={threshold} onChange={(inView, entry) => inView ? this.updatePageBackground(settheme) : false}>
					{({ inView, ref, entry }) => (
			      <div ref={ref}>
			        {children}
			      </div>
			    )}
				</InView>
			</SectionWrapper>
		)
	}
}

Section.defaultProps = {
	settheme: 'bgColor',
	prevTheme: false,
	nextTheme: false,
	zIndex: 1,
	padded: true,
	threshold: [.5, .5]
}

export default withPageContext(Section)
