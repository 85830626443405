import React from 'react'
import { rgba } from 'polished'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import Link from 'src/components/Link'
import Image from 'src/components/Image'
import Section from 'src/components/Section'
import { typography, mq, colors, animations } from 'src/styles'

const Wrapper = styled(Section)`
`

const ThumbnailInfo = styled.div`
	margin-top: .8em;
`

const ProjectsGrid = styled.div`
	display: grid;
  width: 100%;
  direction: ltr;
	grid-template-columns: repeat(12,minmax(0,1fr));

	${ ({ colGap }) => colGap ? `
		${ Array.isArray(colGap) ? `
			column-gap: ${ colGap[0] };
			${ mq.largeAndUp } {
				column-gap: ${ colGap[1] };
			}
			${ mq.extraLargeAndUp } {
				column-gap: ${ colGap[2] };
			}
		` : `
			column-gap: ${ colGap };
		` }
	` : `` }

	${ ({ rowGap }) => rowGap ? `
	  ${ Array.isArray(rowGap) ? `
	  	row-gap: ${ rowGap[0] };
			${ mq.largeAndUp } {
			  row-gap: ${ rowGap[1] };
			}
			${ mq.extraLargeAndUp } {
			  row-gap: ${ rowGap[2] };
			}
		` : `
			row-gap: ${ rowGap };
		`}
	` : `
	` }
`

const Thumbnail = styled(Link)`
	p {
		margin-bottom: 0;
		vertical-align: top;
		${ typography.bodySmall }
		// Cool multilined underlined border
		display: inline;
		${ ({ accentColor }) => accentColor ? `
			background-image: linear-gradient(${ accentColor }, ${ accentColor });
		` : `
			background-image: linear-gradient(currentcolor, currentcolor);
		` }
    background-repeat: no-repeat;
    background-size: 0 2px;
    transition: background-size ${ animations.mediumSpeed } ease-in-out;
		padding-bottom: 3px;
		background-position: left 100%;
	}
	&:hover {
		p {
			background-size: 100% 2px;
		}
	}
	display: block;
	grid-column: span 12;
	${ mq.largeAndUp } {
		grid-column: ${ ({ width }) => `span ${width}`};
		${ ({ width }) => `
			width: ${ width };
		` }
	}
	.gatsby-image-wrapper {
		background: ${ rgba(colors.textColor, 0.05) };
		> div {
			padding-bottom: 66.666% !important;
			${ mq.largeAndUp } {
				padding-bottom: 30vw !important;
			}
		}
	}
`

// const ThumbnailWidths = {
// 	third: '33.333%',
// 	half: '50%',
// 	twoThird: '66.666%'
// }

const ThumbnailWidths = {
	third: 4,
	half: 6,
	twoThird: 8
}

const ProjectGrid = ({
	projects,
	className,
	theme,
	nextTheme,
	prevTheme,
	prevFullWidth,
	nextFullWidth,
	pageId,
	pageType
}) => {
	return (
		<Wrapper
			className={className}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			settheme={theme}
			sectionTheme={theme}
			threshold={0.1, 0.1, 0.1, 0.1}
			prevFullWidth={prevFullWidth}
			nextFullWidth={nextFullWidth}
		>
	  	<Grid small="1 [12] 1">
	  		<ProjectsGrid rowGap={['7.5vw', '4vw', '3.5vw']} colGap={['20px', '20px', '24px']}>
					{projects.map((project, index) => {
						let width = 'third'
						if (pageType !== 'project' && project.thumbnailWidth) {
							width = project.thumbnailWidth
						}
						if (project.id !== pageId) {
							return (
								<Thumbnail
									key={project.id}
									to={'/projects' + project.path}
									style="none"
									width={ThumbnailWidths[width]}
									accentColor={project.accentColor}
								>
									{width === 'third' && project.smallThumbnail ? (
										<div>
										<Image
											// large={project.smallThumbnail}
											medium={project.smallThumbnail}
											small={project.thumbnail}
											sizes={'(max-width: ' + mq.mediumBreakpoint + 'px) 100vw, ' + (100 / 14) * ThumbnailWidths[width] + 'vw'}
										/>
										</div>
									) : (
										<div>
										<Image
											image={project.thumbnail}
											sizes={'(max-width: ' + mq.mediumBreakpoint + 'px) 100vw, ' + (100 / 14) * ThumbnailWidths[width] + 'vw'}
										/>
										</div>
									)}
									<ThumbnailInfo>
										<p>
											<b>{project.title}</b>
											{project.tagline && '—' + project.tagline}
										</p>
										</ThumbnailInfo>
								</Thumbnail>
							)
						}
					})}
				</ProjectsGrid>
			</Grid>
	  </Wrapper>
	)
}

export default ProjectGrid
