import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Image from 'src/components/Image'
import Video from 'src/components/Video'
import ThemeSelector from 'src/components/ThemeSelector'
import Grid from 'src/components/Grid'
import Caption from 'src/components/Caption'
import { colors, mq, util } from 'src/styles'
import ScrollEntrance from 'src/components/ScrollEntrance'

const WideMediaWrap = styled(ThemeSelector)`
	position: relative;
	z-index: 2;
`

const MediaItem = styled.div`
	margin: 0 auto;
	${ ({ maxWidth, type }) => maxWidth ? `
		max-width: ${ maxWidth };
	` : `
		${ type === 'normal' ? `
			max-width: 100%;
		` : `` }
		${ type === 'web' ? `
			${ util.responsiveStyles('max-width', 1200, 1000, 800, 700) }
		` : `` }
		${ type === 'mobile' ? `
			${ util.responsiveStyles('max-width', 400, 300, 300, 250) }
		` : `` }
	` }
`

const CaptionOverlay = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 1em;
	z-index: 3;
	${ mq.mediumAndBelow } {
		position: relative;
		bottom: auto;
		top: 100%;
		z-index: 1;
		background: ${ colors.black };
		padding-top: 8px;
	}
`

const CaptionBlock = styled.div`
`

const WideMedia = ({
	image,
	simpleImage,
	video,
	nextSectionBg,
	customTheme,
	fullWidth,
	theme,
	prevTheme,
	nextTheme,
	caption,
	maxWidth,
	lastItem,
	prevFullWidth,
	nextFullWidth,
	type
}) => {
	
	let mediaType = type || 'normal'
	if (type == null) {
		mediaType = 'normal'
	}
	if (image && image.type) {
		mediaType = image.type || 'normal'
	}

	let altText = ''
	if (simpleImage) {
		altText = simpleImage.title
	} else if (image) {
		altText = image.title
	}

	if (!fullWidth) {
		return (
			<Section
				settheme={customTheme || theme}
				nextTheme={nextTheme}
				prevTheme={prevTheme}
				lastItem={lastItem}
				prevFullWidth={prevFullWidth}
				nextFullWidth={nextFullWidth}
			>
				<ScrollEntrance>
					<Grid small="1 [12] 1">
						<div>
							<MediaItem type={mediaType} maxWidth={maxWidth}>
								{(image || simpleImage) && !video ? (
									<Image
										image={simpleImage || image.image}
										small={image && image.small}
										medium={image && image.medium}
										large={image && image.large}
										type={mediaType}
										alt={altText}
										sizes="86vw"
									/>
								) : false}
								{video && (
									<Video type={mediaType} url={video && video.file.url} playing={true} loop={true} coverImage={image ? image.image : false}/>
								)}
								{caption && (
									<Caption>{caption}</Caption>
								)}
							</MediaItem>
						</div>
					</Grid>
				</ScrollEntrance>
			</Section>
		)
	}

	return (
		<ScrollEntrance>
			<WideMediaWrap settheme={customTheme || theme} transitionBg={false}>
				<MediaItem type={mediaType} maxWidth={maxWidth}>
					{(image || simpleImage) && !video ? (
						<Image
							image={simpleImage || image.image}
							small={image && image.small}
							medium={image && image.medium}
							large={image && image.large}
							type={mediaType}
							alt={altText}
							sizes="100vw"
						/>
					) : false}
					{video && (
						<Video type={mediaType} url={video && video.file.url} playing={true} loop={true} coverImage={image ? image.image : false} />
					)}
					{caption && (
						<Grid small="1 [12] 1">
							<div>
								<CaptionBlock>
									<Caption>{caption}</Caption>
								</CaptionBlock>
							</div>
						</Grid>
					)}
				</MediaItem>
			</WideMediaWrap>
		</ScrollEntrance>
	)
}

WideMedia.defaultProps = {
	fullWidth: false,
	maxWidth: false,
	theme: 'bgColor'
}

export default WideMedia
