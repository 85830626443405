import React, { Component } from 'react'
import styled from '@emotion/styled'
import { colors } from 'src/styles'
import { Spring, animated, config } from 'react-spring/renderprops'
import { interpolate } from 'flubber'

const Wrapper = styled.div`
	width: 100%;
	svg {
		display: block;
		overflow: visible;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: auto;
		overflow: visible;
		* {
			fill: transparent;
			stroke: ${ colors.blue };
			stroke-width: 3px;
		}
	}
`

class ProcessIcons extends Component {
	state = {
    previousShape: this.props.icon
  }

  setPrevious = () => {
  	if (this.state.previousShape !== this.props.icon) {
	  	this.setState({
	  		previousShape: this.props.icon
	  	})
  	}
 	}

	render () {
		const { icon, className } = this.props
		const { previousShape } = this.state

		const shapes = {
			triangle: "M412.506,357.241l-76.299-0.047h-59.565H206.42h-70.223H76.632 L0,357.241l41.944-73.969l40.696-70.221l34.056-58.654l23.846-40.657L206.253,0l65.823,113.741l22.245,38.838l34.972,60.471 l40.743,70.223L412.506,357.241z",
			squiggle: "M361.454,134.35c-8.876-21.429-2.227-55.24-20.036-72.785 c-17.809-17.545-51.019-9.903-70.879-18.129c-20.216-8.374-38.822-37.295-64.286-37.305c-24.565-0.01-43.242,28.588-64.286,37.305 c-21.429,8.876-55.24,2.227-72.786,20.036s-9.903,51.019-18.129,70.879c-8.374,20.216-37.294,38.821-37.304,64.285 c-0.01,24.565,28.588,43.243,37.305,64.286c8.717,21.044,1.701,54.472,19.079,71.836c18.013,17.999,51.619,10.705,71.836,19.079 c20.217,8.374,38.822,37.295,64.286,37.305c24.565,0.01,43.242-28.588,64.286-37.305c21.043-8.717,54.472-1.701,71.835-19.079 c17.999-18.013,10.705-51.619,19.079-71.836c8.374-20.216,37.295-38.821,37.305-64.286 C398.768,174.071,370.17,155.394,361.454,134.35z",
			circle: "M375.785,128.414c-9.696-23.407-23.677-43.742-40.656-60.471 c-16.98-16.729-36.96-29.853-58.653-38.838c-22.083-9.147-45.943-14.006-70.222-14.016c-23.422-0.009-47.235,4.495-70.222,14.016 C112.623,38.8,92.289,52.781,75.559,69.761s-29.853,36.96-38.839,58.654c-9.147,22.083-14.006,45.942-14.016,70.221 c-0.009,23.422,4.495,47.236,14.016,70.223c9.521,22.987,23.175,43.01,39.744,59.566c17.175,17.161,37.482,30.597,59.566,39.744 s45.943,14.006,70.223,14.016c23.422,0.009,47.235-4.495,70.222-14.016c22.987-9.521,43.01-23.175,59.565-39.744 c17.161-17.175,30.597-37.482,39.744-59.566c9.147-22.083,14.007-45.943,14.016-70.222 C389.81,175.214,385.307,151.401,375.785,128.414z",
			rainbow: "M375.785,220.188c-9.696-23.407-23.677-43.742-40.656-60.471 c-16.98-16.729-36.96-29.853-58.653-38.838c-22.083-9.147-45.943-14.006-70.222-14.016c-23.422-0.009-47.235,4.495-70.222,14.016 c-23.408,9.696-43.742,23.677-60.471,40.657s-29.853,36.96-38.839,58.654c-9.147,22.083-14.006,45.942-14.016,70.221l14.016,0 h39.744h59.566h70.223h70.222h59.565h39.744l14.016,0C389.81,266.988,385.307,243.175,375.785,220.188z",
		}

		const shapes2 = {
			triangle: "M369.132,282.272l43.375,74.969l-65.039-0.313h-55.524h-55.524h-55.524h-55.524 L0,357.241l42.443-74.969l28.75-49.253l23.836-41.14l28.526-49.189h82.865h82.653l27.036,47.914l24.701,42.414L369.132,282.272z",
			squiggle: "M324.11,198.637c-0.006,15.59,13.584,35.412,6.855,51.657 c-6.729,16.245-30.355,20.652-41.375,31.68c-10.63,10.639-14.77,34.37-31.68,41.375c-16.91,7.004-36.618-6.849-51.657-6.855 c-15.59-0.006-35.412,13.584-51.658,6.855c-16.245-6.729-20.652-30.355-31.68-41.375c-10.639-10.63-34.37-14.77-41.375-31.68 c-7.004-16.91,6.849-36.618,6.855-51.658c0.006-15.59-13.584-35.412-6.855-51.657c6.61-15.958,30.051-20.191,40.793-31.095 s15.042-34.828,32.262-41.961c16.91-7.004,36.618,6.849,51.657,6.855c15.59,0.006,35.412-13.584,51.657-6.855 c15.958,6.61,20.191,30.051,31.094,40.793c10.903,10.742,34.828,15.042,41.961,32.261 C337.97,163.889,324.116,183.597,324.11,198.637z",
			circle: "M334.993,198.637c-0.007,17.03-3.415,33.765-9.831,49.254 c-6.416,15.489-15.839,29.733-27.876,41.779c-11.612,11.621-25.656,21.198-41.779,27.876c-16.123,6.678-32.825,9.837-49.254,9.831 c-17.03-0.007-33.765-3.415-49.254-9.831s-29.733-15.839-41.779-27.876c-11.621-11.612-21.198-25.656-27.876-41.779 c-6.678-16.123-9.837-32.826-9.831-49.254c0.007-17.029,3.415-33.764,9.831-49.253c6.303-15.216,15.507-29.23,27.241-41.14 S140.582,86.528,157,79.727c16.123-6.678,32.825-9.837,49.254-9.831c17.03,0.007,33.765,3.415,49.254,9.831 c15.216,6.302,29.23,15.507,41.139,27.241c11.91,11.734,21.716,25.996,28.516,42.414C331.841,165.506,335,182.208,334.993,198.637z",
			rainbow: "M334.993,290.411l-9.831,0h-27.876h-41.779h-49.254H157H115.22H87.344l-9.831,0 c0.007-17.029,3.415-33.764,9.831-49.253c6.303-15.216,15.507-29.23,27.241-41.14c11.734-11.91,25.996-21.716,42.415-28.517 c16.123-6.678,32.825-9.837,49.254-9.831c17.03,0.007,33.765,3.415,49.254,9.831c15.216,6.302,29.23,15.507,41.139,27.241 c11.91,11.734,21.716,25.996,28.516,42.414C331.841,257.28,335,273.982,334.993,290.411z",
		}

		const shapes3 = {
			triangle: "M358.58,263.725l-13.453-22.489l-90.136-0.053l-48.571-0.053 l-48.571-0.053l-91.318,0.211l-13.284,22.437L36.892,292.28L0,357.241l113.611-0.134h44.238h48.571h48.571h44.237l113.278,0.134 L374.92,292.28L358.58,263.725z",
			squiggle: "M269.363,172.496c-3.609-8.714-0.906-22.463-8.147-29.597 c-7.242-7.135-20.746-4.027-28.822-7.372c-8.221-3.405-15.786-15.165-26.141-15.169c-9.989-0.004-17.584,11.625-26.141,15.169 c-8.714,3.609-22.463,0.906-29.597,8.147c-7.135,7.242-4.027,20.746-7.372,28.822c-3.405,8.221-15.165,15.786-15.169,26.141 c-0.004,9.989,11.625,17.584,15.169,26.141c3.544,8.557,0.692,22.15,7.758,29.211c7.325,7.319,20.99,4.353,29.211,7.758 c8.221,3.405,15.786,15.165,26.141,15.169c9.989,0.004,17.584-11.625,26.141-15.169c8.557-3.544,22.15-0.692,29.211-7.758 c7.319-7.325,4.353-20.99,7.758-29.211c3.405-8.221,15.165-15.786,15.17-26.141C284.537,188.648,272.908,181.053,269.363,172.496z",
			circle: "M275.191,170.082c-3.943-9.518-9.628-17.787-16.532-24.59 c-6.905-6.803-15.029-12.139-23.851-15.793c-8.98-3.72-18.682-5.696-28.555-5.699c-9.524-0.004-19.208,1.828-28.555,5.699 c-9.518,3.943-17.787,9.628-24.59,16.533s-12.139,15.029-15.793,23.851c-3.719,8.98-5.695,18.682-5.699,28.555 c-0.004,9.524,1.828,19.208,5.699,28.555c3.872,9.347,9.424,17.49,16.161,24.222c6.984,6.978,15.242,12.442,24.222,16.161 c8.98,3.72,18.682,5.696,28.555,5.699c9.524,0.004,19.208-1.828,28.555-5.699c9.347-3.872,17.489-9.424,24.221-16.161 c6.978-6.984,12.442-15.242,16.161-24.222c3.72-8.98,5.696-18.682,5.699-28.555C280.894,189.112,279.063,179.429,275.191,170.082z",
			rainbow: "M275.191,261.855c-3.943-9.518-9.628-17.787-16.532-24.59 c-6.905-6.803-15.029-12.139-23.851-15.793c-8.98-3.72-18.682-5.696-28.555-5.699c-9.524-0.004-19.208,1.828-28.555,5.699 c-9.518,3.943-17.787,9.628-24.59,16.533c-6.803,6.905-12.139,15.029-15.793,23.851c-3.719,8.98-5.695,18.682-5.699,28.555l5.699,0 h16.161h24.222h28.555h28.555h24.222h16.161l5.699,0C280.894,280.886,279.063,271.203,275.191,261.855z",
		}

		const shapes4 = {
			triangle: "M206.42,282.272L206.42,282.272L206.42,282.272L206.42,282.272 L206.42,282.272L206.42,282.272L206.42,282.272L206.42,282.272L206.42,282.272L206.42,282.272L206.42,282.272L206.42,282.272 L206.42,282.272L206.42,282.272L206.42,282.272L206.42,282.272L206.42,282.272z",
			squiggle: "M229.23,198.637c-0.001,3.039-0.61,6.026-1.755,8.791 c-1.145,2.764-2.827,5.307-4.975,7.457c-2.072,2.074-4.579,3.783-7.457,4.975c-2.878,1.192-5.859,1.756-8.791,1.755 c-3.039-0.001-6.026-0.609-8.791-1.755c-2.764-1.145-5.307-2.827-7.457-4.975c-2.074-2.072-3.783-4.579-4.975-7.457 c-1.192-2.878-1.756-5.859-1.755-8.791c0.001-3.039,0.609-6.026,1.755-8.791c1.125-2.716,2.768-5.217,4.862-7.342 c2.094-2.126,4.64-3.876,7.57-5.09c2.878-1.192,5.859-1.756,8.791-1.755c3.039,0.001,6.026,0.609,8.791,1.755 c2.716,1.125,5.217,2.768,7.342,4.862c2.126,2.094,3.876,4.64,5.09,7.57C228.668,192.723,229.232,195.705,229.23,198.637z",
			circle: "M232.105,198.637c-0.001,3.42-0.686,6.78-1.974,9.89 c-1.288,3.11-3.181,5.971-5.598,8.39c-2.332,2.334-5.152,4.257-8.39,5.598c-3.238,1.341-6.592,1.975-9.89,1.974 c-3.42-0.001-6.78-0.686-9.891-1.974c-3.11-1.288-5.971-3.181-8.39-5.598c-2.334-2.332-4.257-5.152-5.598-8.39 c-1.341-3.238-1.975-6.592-1.974-9.891c0.001-3.42,0.686-6.78,1.974-9.89c1.266-3.055,3.114-5.87,5.47-8.261 c2.356-2.392,5.22-4.361,8.517-5.726c3.238-1.341,6.592-1.975,9.89-1.974c3.42,0.001,6.78,0.686,9.891,1.974 c3.055,1.266,5.87,3.114,8.261,5.47c2.392,2.356,4.361,5.22,5.726,8.517C231.472,191.984,232.107,195.338,232.105,198.637z",
			rainbow: "M232.105,290.41l-1.974,0h-5.598h-8.39h-9.89h-9.891h-8.39h-5.598 h-1.974c0.001-3.42,0.686-6.78,1.974-9.89c1.266-3.055,3.114-5.87,5.47-8.261c2.356-2.392,5.22-4.361,8.517-5.726 c3.238-1.341,6.592-1.975,9.89-1.974c3.42,0.001,6.78,0.686,9.891,1.974c3.055,1.266,5.87,3.114,8.261,5.47 c2.392,2.356,4.361,5.22,5.726,8.517C231.472,283.758,232.107,287.112,232.105,290.41z",
		}

		const interpolator = interpolate(shapes[previousShape], shapes[icon], { maxSegmentLength: 5 })
		const interpolator2 = interpolate(shapes2[previousShape], shapes2[icon], { maxSegmentLength: 5 })
		const interpolator3 = interpolate(shapes3[previousShape], shapes3[icon], { maxSegmentLength: 5 })
		const interpolator4 = interpolate(shapes4[previousShape], shapes4[icon], { maxSegmentLength: 5 })

		return (
		  <Wrapper className={className}>
		  	<svg viewBox="0 0 412.506 391.142">
			  		<Spring
			  			reset
			  			native
			  			delay={0}
			  			// immediate={true}
			  			// config={{ tension: 180, friction: 12 }}
		          from={{ t: 0 }}
		          to={{ t: 1 }}
		          onRest={this.setPrevious}
						>
		          {({ t }) => {
		          	return <g>
		          		<animated.path d={t.interpolate(interpolator)} />
		          		<animated.path d={t.interpolate(interpolator2)} />
		          		<animated.path d={t.interpolate(interpolator3)} />
		          		<animated.path d={t.interpolate(interpolator4)} />
		          	</g>
		          }}
		        </Spring>
		  	</svg>
		  </Wrapper>
		)
	}
}

ProcessIcons.defaultProps = {
	icon: 'triangle'
}

export default ProcessIcons
