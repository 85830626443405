import React from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import Link from 'src/components/Link'
import ConditionalRender from 'src/components/ConditionalRender'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ContentfulRichText from 'src/components/ContentfulRichText'
import TextLockup from 'src/components/TextLockup'
import { typography, colors, util, mq } from 'src/styles'
import BalanceText from 'react-balance-text'
import Section from 'src/components/Section'

const TextContainer = styled(TextLockup)`
	width: 100%;
	margin: 0 auto;
	${ util.responsiveStyles('max-width', 800, 750, 750, 600) }
	${ ({ type }) => type === 'serviceList' ? `
		ul {
			list-style: none;
			${ typography.bodySmall }
			${ mq.mediumAndUp } {
				display: flex;
				flex-wrap: wrap;
				li {
					width: 50%;
					flex-grow: 1;
					margin: 0;
				}
			}
			p {
				${ typography.bodySmall }
			}
		}
	` : `` }
`

const RichTextSection = ({
	theme,
	prevTheme,
	nextTheme,
	eyebrow,
	headline,
	headlineSize,
	text,
	buttons,
	prevFullWidth,
	nextFullWidth,
	type
}) => {
	let dividerColor = 'currentcolor'

	if (theme === 'white' || theme === 'bgColor' || !theme) {
		dividerColor = 'default'
	}

	return (
		<Section
			settheme={theme}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			prevFullWidth={prevFullWidth}
			nextFullWidth={nextFullWidth}
		>
			<Grid
				small="1 [12] 1"
				large="2 [10] 2"
			>
				<TextContainer
					theme={theme}
					eyebrow={eyebrow}
					headline={type === 'serviceList' ? 'Services' : headline}
					headlineSize={type === 'serviceList' ? 'h5' : headlineSize}
					text={text}
					textSize="bodyMedium"
					buttons={buttons}
					alignment="left"
					type={type}
				/>
			</Grid>
		</Section>
	)
}

RichTextSection.defaultProps = {
	headlineSize: 'h3'
}

export default RichTextSection
