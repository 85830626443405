import React, { Component } from 'react'
import styled from '@emotion/styled'
import { lighten } from 'polished'

import ATF from 'src/components/ATF'

import Link from 'src/components/Link'
import Grid from 'src/components/Grid'
import Logo from 'src/components/Logo'
import MailchimpSignup from 'src/components/MailchimpSignup'
import ThemeSelector from 'src/components/ThemeSelector'
import ConditionalRender from 'src/components/ConditionalRender'
import ContentfulRichText from 'src/components/ContentfulRichText'

import { animations } from 'src/styles'

const ContactInfo = styled.div`
	p {
    margin-top: 1.5em;
    margin-bottom: 0;
    a {
    	position: relative;
    	border: none;
    	&:after {
				content: '';
				display: block;
				position: absolute;
				top: 100%;
				left: -.01em;
				right: -.01em;
				height: 2px;
				background: currentcolor;
				transform: scaleX(0);
				transform-origin: right center;
				transition: transform ${ animations.mediumSpeed } ease-in-out;
			}
			&:hover {
				&:after {
					transform-origin: left center;
					transform: scaleX(1);
				}
			}
    }
		b {
			display: block;
			font-weight: 700;
		}
	}
`

class Footer extends Component {
	render () {
		const {
			footerLargeText,
			footerTopText,
			footerBottomText,
		} = this.props

		return (
			<div>
				<ATF
					headline={footerLargeText}
					headlineSize="h1"
					theme="blue"
					hAlignment="left"
					vAlignment="bottom"
					textAlignment="left"
					fullHeight={true}
					headerArea2={footerTopText}
					text={<div>
						<ContactInfo>
							<ContentfulRichText richText={footerBottomText.json} />
						</ContactInfo>
					</div>}
				>
					<ContactInfo>
					<p><Link to="https://instagram.com/gordilsandwillis" external settheme="bgColor" style="arrowLink">Follow Us</Link></p>
					</ContactInfo>
				</ATF>
			</div>
		)
	}
}

export default Footer
