import React from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import ConditionalRender from 'src/components/ConditionalRender'
import TextLockup from 'src/components/TextLockup'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ProcessIcons from 'src/components/ProcessIcons'
import { InView } from 'react-intersection-observer'
import { mq, util,colors } from 'src/styles'
import ResponsiveComponent from 'src/components/ResponsiveComponent'

import Pyramid from 'src/assets/images/icon-pyramid.svg'
import Squiggle from 'src/assets/images/icon-squiggle.svg'
import Target from 'src/assets/images/icon-target.svg'
import Rainbow from 'src/assets/images/icon-rainbow.svg'

const TextSection = styled.div`
	${ mq.largeAndUp } {
		min-height: 70vh;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
`

const IconWrapper = styled.div`
	margin: 0 auto;
	display: block;
	width: 100%;
	margin: ${ ({ index }) => index === 0 ? `-3vw 0 3vw` : `14vw 0 3vw` };
	svg * {
		stroke-width: 4px;
	}
	${ mq.largeAndUp } {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 100%;
		height: 70vh;
		align-items: center;
		justify-content: center;
		position: sticky;
		top: 15vh;
		margin: 0;
		svg * {
			stroke-width: 3px;
		}
	}
	${ mq.extraLargeAndUp } {
		svg * {
			stroke-width: 3px;
		}
	}
`

const StyledIcons = styled(ProcessIcons)`
	${ util.responsiveStyles('max-width', 650, 450, 400, 200) }
`

class ProcessSection extends React.Component {
	state = {
		currentIcon: 'triangle'
	}
	render () {

		const {
			theme,
			customTheme,
			prevTheme,
			nextTheme,
			eyebrow,
			headline,
			headlineSize,
			text,
			imagePosition,
			image,
			video,
			buttons,
			additions,
			sections
		} = this.props

		const { currentIcon } = this.state

		const icons = [
			'triangle',
			'squiggle',
			'circle',
			'rainbow'
		]

		const mobileIcons = [
			<Pyramid/>,
			<Squiggle/>,
			<Target/>,
			<Rainbow/>
		]

		const setIcon = icon => {
			setTimeout(() => {
				if (this.state.currentIcon !== icon) {
					this.setState({ currentIcon: icon })
				}
			}, 100)
		}

		return (
			<Section
				settheme={theme}
				prevTheme={prevTheme}
				nextTheme={nextTheme}
				sectionTheme="#DFECFF"
				threshold={.1, .1, .1, .1}
				id="our-process"
			>	
				<Grid
					small="1 [12] 1"
					medium="1 [6] 1 [5] 1"
					large="1 [6] 1 [5] 1"
					extraLarge="1 [6] 1 [5] 1"
					gridDirection="rtl"
					rowGap="7vw"
					vAlign="top"
				>	
					<ResponsiveComponent medium={
						<IconWrapper>
							<StyledIcons icon={currentIcon}/>
						</IconWrapper>
					} small=' ' />
					<div>
						{sections.map((section, index) => (
							<InView key={section.id} as="div" onChange={(inView, entry) => inView && setIcon(icons[index])} threshold={.5} triggerOnce={false}>
								<ResponsiveComponent small={
									<IconWrapper index={index}>
										<ScrollEntrance>
											{mobileIcons[index]}
										</ScrollEntrance>
									</IconWrapper>
								} medium=' ' />
								<TextSection index={index}>
									<TextLockup
										alignment="left"
										specialList
										headline={section.headline}
										headlineSize="h2"
										text={section.text}
										// textSize="bodyMedium"
										eyebrow={section.eyebrow}
										buttons={section.buttons}
										theme={section.theme}
									/>
								</TextSection>
							</InView>
						))}
					</div>
				</Grid>
			</Section>
		)
	}
}

ProcessSection.defaultProps = {
	image: false,
	imagePosition: 'left',
	additions: false,
	headlineSize: 'h3',
	sections: []
}

export default ProcessSection
