import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { colors } from 'src/styles'
import ScrollListener from 'src/components/ScrollListener'
import { ScrollPercentage } from 'react-scroll-percentage'

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 6px;
	z-index: 10;
	transition: transform .2s ease-in-out;
	${ ({ progress }) => !progress || progress >= 1 && `
		transform: translate3d(0, -101%, 0);
	` }
`

const Bar = styled.div`
	width: 100%;
	height: 100%;
	background: ${ ({ color }) => color ? color : colors.textColor };
	transform-origin: 0% 0%;
	${ ({ progress }) => `
		transform: scaleX(${ progress < .005 ? 0 : progress });
	` }
`

const ScrollProgressBar = ({ className, color, progressOf }) => (
	<Fragment>
		<ScrollPercentage threshold={1}>
			{({ percentage, ref, entry }) => { 
				const barSize = (1 - percentage).toPrecision(3)
				return (
				<Fragment>
				  <Wrapper className={className} progress={barSize}>
				  	<Bar progress={barSize} color={color}/>
				  </Wrapper>
				  <div ref={ref} style={{ 
				  	width: '0',
				  	background: 'transpatent',
				  	position: 'absolute',
				  	top: 0,
				  	left: 0,
				  	bottom: 0,
				  	zIndex: -1
				  }}/>
			  </Fragment>
		  	)
		  }}
	  </ScrollPercentage>
  </Fragment>
)

ScrollProgressBar.defaultProps = {
	progressOf: 5000
}

export default ScrollProgressBar
