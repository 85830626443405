import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import { rgba } from 'polished'
import { withPageContext } from 'src/contexts/pageContext'
import ReactGA from 'react-ga'
import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import LogoNav from 'src/components/LogoNav'
import Footer from 'src/components/Footer'
import { colors } from 'src/styles'
import ThemeSelector from 'src/components/ThemeSelector'
import ScrollProgressBar from 'src/components/ScrollProgressBar'

import ContactDrawer from 'src/components/ContactDrawer'

const propTypes = {
	data: PropTypes.object.isRequired,
}

const PageWrapper = styled(ThemeSelector)`
	${ ({ pageAccent, settheme }) => pageAccent !== colors.textColor && `
		*::selection {
	    background: ${ `${ rgba(pageAccent, 0.9) }` };
	    ${ settheme && settheme.includes('#') ? `
		    color: ${ settheme };
	    ` : `
	    	color: ${ colors.white };
	    ` }
	  }
	  p {
	  	a {
	  		&:hover {
	  			border-color: ${ `${ rgba(pageAccent, 0.9) }` };
	  		}
	  	}
	  }
  ` }
`

class PageTemplate extends React.Component {
	state = {
		drawerOpen: false
	}

	constructor(props) {
    super(props);
    this.pageSections = React.createRef();
  }

  openDrawer = () => {
		this.setState({ drawerOpen: true })
	}

	closeDrawer = () => {
		this.setState({ drawerOpen: false })
	}

  componentDidMount () {
		const page = this.props.data.allContentfulPage.edges[0].node
		const { backgroundColor } = page
		if (this.props.pageContext.pageBg !== backgroundColor) {
			this.props.pageContext.updateState('pageBg', backgroundColor)
		}

		console.log(`%c
╔═╗╔═╗╦═╗╔╦╗╦╦  ╔═╗  ┬  ╦ ╦╦╦  ╦  ╦╔═╗
║ ╦║ ║╠╦╝ ║║║║  ╚═╗ ┌┼─ ║║║║║  ║  ║╚═╗
╚═╝╚═╝╩╚══╩╝╩╩═╝╚═╝ └┘  ╚╩╝╩╩═╝╩═╝╩╚═╝
Feeding The Internet       2016 — ${new Date().getFullYear()}
Wan't a job? info@gordilsandwillis.com
`, "font-family:monospace")
	}

	render () {
		const site = this.props.data.allContentfulSiteSettings.edges.filter(edge => !edge.node.title.includes('PLACEHOLDER'))[0].node
		const page = this.props.data.allContentfulPage.edges[0].node
		const projectGrid = this.props.data.allContentfulProjectGrid.edges.filter(edge => edge.node.internalName.includes('All Projects'))[0].node
		const { sections, accentColor, backgroundColor } = page
		const backgroundColorContext = this.props.pageContext.pageBg
		const hasAtf = sections && sections[0].__typename === 'ContentfulAboveTheFold'

		return (
			<Fragment key={page.path}>
				<SEO
					title={page.title}
					description={page.seoDescription}
					siteSettings={site}
					shareImage={page.shareImage ? 'https:' + page.shareImage.file.url : false}
				/>
				
				<PageWrapper
					settheme={backgroundColorContext}
					pageAccent={accentColor || colors.textColor}
					pageSpecificBg={backgroundColor}
				>
					<LogoNav contentHeight={200} openDrawer={this.openDrawer}/>
					<div style={{ position: 'relative' }}>
						{page.type === 'project' && <ScrollProgressBar color={accentColor} progressOf={200}/>}
						{sections && sections.map((section, index) => {
							let prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
							let nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
							let prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].fullWidth
							let nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].fullWidth
							let lastItem = false

							if (index + 1 === sections.length && page.type !== 'project') {
								lastItem = true
							}

							return (
								<ComponentRenderer
									title={page.title}
									pageAccent={accentColor}
									prevTheme={prevTheme}
									nextTheme={nextTheme}
									prevFullWidth={prevFullWidth}
									nextFullWidth={nextFullWidth}
									key={section.id}
									item={section}
									index={index}
									thumbnailWidth={page.thumbnailWidth}
									lastItem={lastItem}
									pageId={page.id}
									pageType={page.type}
									tagline={page.tagline}
								/>
							)
						})}
					</div>
					{page.type === 'project' &&
						<ComponentRenderer
							pageAccent={accentColor}
							item={projectGrid}
							lastItem={true}
							pageId={page.id}
							pageType={page.type}
						/>
					}
					<Footer {...site} />
					<ContactDrawer closeDrawer={this.closeDrawer} open={this.state.drawerOpen}/>
				</PageWrapper>
			</Fragment>
		)
	}
}

PageTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings(filter: {internalName: {nin: "PLACEHOLDER Site Settings"}}) {
			edges {
				node {
					...SiteSettings
				}
			}
		}
		allContentfulProjectGrid(filter: {internalName: {nin: "PLACEHOLDER"}}) {
			edges {
				node {
					...ProjectGrid
				}
			}
		}
    allContentfulPage(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					path
					title
					type
					tagline
					seoDescription
					backgroundColor
					accentColor
					thumbnail {
	          fluid {
	            src
	            srcSet
	            srcWebp
	            srcSetWebp
	            sizes
	          }
	        }
	        thumbnailWidth
	        shareImage {
	          file {
	            url
	          }
	        }
					sections {
						...AboveTheFold
						...CalloutText
						...FiftyFifty
						...RichTextSection
						...WideImageVideo
						...MultipleImages
						...ProjectGrid
						...ProcessSection
					}
				}
			}
    }
	}
`

export default withPageContext(PageTemplate)
