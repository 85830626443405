import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import ConditionalRender from 'src/components/ConditionalRender'
import Image from 'src/components/Image'
import Video from 'src/components/Video'
import TextLockup from 'src/components/TextLockup'
import ScrollEntrance from 'src/components/ScrollEntrance'
import StackedImages from 'src/components/StackedImages'
import { mq } from 'src/styles'

const FFSection = styled(Section)`
	overflow: hidden;
`

const FFTextLockup = styled(TextLockup)`
	position: relative;
	z-index: 2;
`

const FFImageWrap = styled.div``

const FiftyFifty = ({
	theme,
	customTheme,
	prevTheme,
	nextTheme,
	eyebrow,
	headline,
	headlineSize,
	text,
	imagePosition,
	FFimg,
	video,
	buttons,
	additions,
	illustration,
	lastItem,
	prevFullWidth,
	nextFullWidth
}) => {

	let gridSetup = {
		small: '1 [12] 1',
		medium: '1 [6] 1 [5] 1',
		large: '2 [11] 2 [11] 2',
		extraLarge: '1 [6] 1 [5] 1',
		imagePosition: 'ltr',
		textGrid: '[1]'
	}

	if (imagePosition === 'right') {
		gridSetup.imagePosition = 'rtl'
	} else if (imagePosition === 'hangLeft' || imagePosition === 'hangRight') {
		gridSetup = {
			small: '[13] 1',
			medium: '[7] 1 [5] 1',
			large: '[7] 1 [5] 1',
			extraLarge: '[13] 2 [10] 3',
			imagePosition: 'ltr',
			textGrid: '[12] 1'
		}
	}

	const image = FFimg[0]

	return (
		<FFSection
			settheme={customTheme || theme}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			lastItem={lastItem}
			prevFullWidth={prevFullWidth}
			nextFullWidth={nextFullWidth}
		>
			<Grid
				small={gridSetup.small}
				medium={gridSetup.medium}
				large={gridSetup.large}
				extraLarge={gridSetup.extraLarge}
				gridDirection={gridSetup.imagePosition}
				rowGap="7vw"
				vAlign="center"
			>	
				<ScrollEntrance>
					<ConditionalRender condition={image && !video}>
						<FFImageWrap>
							{image && (
								image.__typename !== 'ContentfulStackedImages' ? (
									<Image
										image={image.image}
										link={image.link}
										small={image.small}
										medium={image.medium}
										large={image.large}
										type={image.type || 'normal'}
										alt={(image && image.description) || (image && image.title)}
										sizes={"(max-width: " + mq.mediumBreakpoint + "px) 100vw, 50vw"}
									/>
								) : (
									<StackedImages
										images={image.images}
										link={image.link}
										effect={image.type || 'parallax'}
										horizontalOffset={image.horizontalOffset}
										sizes={"(max-width: " + mq.mediumBreakpoint + "px) 100vw, 50vw"}
									/>
								)
							)}
						</FFImageWrap>
					</ConditionalRender>
					{illustration && illustration}
					<ConditionalRender condition={video}>
						<div>
							<Video url={video && video.file.url} playing={true} loop={true}/>
						</div>
					</ConditionalRender>
				</ScrollEntrance>
				
				<Grid
					small={gridSetup.textGrid}
					medium="[1]"
				>
					<FFTextLockup
						alignment="left"
						specialList
						headline={headline}
						headlineSize={headlineSize}
						text={text}
						eyebrow={eyebrow}
						buttons={buttons}
						theme={theme}
						additions={additions}
					/>
				</Grid>
			</Grid>
		</FFSection>
	)
}

FiftyFifty.defaultProps = {
	image: false,
	imagePosition: 'left',
	additions: false,
	headlineSize: 'h3'
}

export default FiftyFifty
