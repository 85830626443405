import React, { Component } from 'react'
import styled from '@emotion/styled'
import { Parallax } from 'react-scroll-parallax'

import Image from 'src/components/Image'
import tickl from 'tickl'
import Tilt from 'react-tilt'
import { Link } from 'gatsby'

import { mq } from 'src/styles'

const Wrapper = styled.div`
	position: relative;
	display: block;
	margin-left: ${ ({ offset }) => offset || 0 }%;
	margin-right: ${ ({ offset }) => offset || 0 }%;
`

const LayerStyle = (index, imageCount) => `
	z-index: ${ imageCount - index };
	${ index === 0 ? `
		position: relative;
	` : `
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	` }
`

const Item = styled.div`
	${ ({ index, imageCount }) => LayerStyle(index, imageCount) }
	${ ({ effect, index, imageCount }) => effect === 'tilt' ? `
		transform: translateZ(${ 20 * (imageCount - index) }px);
		transition: transform 2s cubic-bezier(.06, .83, .1, 1);
	` : `` }
`

const ParallaxItem = styled(Parallax)`
	${ ({ index, imageCount }) => LayerStyle(index, imageCount) }
`

const ParallaxWrap = styled.div`
	position: relative;
	.parallax-inner {
		transition: transform 2s cubic-bezier(.06, .83, .1, 1);
	}
`

const TickleWrap = styled.div`
	${ Item } {
		pointer-events: none;
		transition: transform 3s cubic-bezier(.06, .83, .1, 1);
	}
`

const TiltWrap = styled(Tilt)`
	transform-style: preserve-3d;
	&:not(:hover) {
		${ Item } {
			transform: translateZ(0);
		}
	}
`

class StackedImages extends Component {
  constructor(props) {
    super(props);
    this.handleScrollTo = this.props.handler;
    this.ticklWrap = React.createRef();
    this.ticklLayer6 = React.createRef();
    this.ticklLayer5 = React.createRef();
    this.ticklLayer4 = React.createRef();
    this.ticklLayer3 = React.createRef();
    this.ticklLayer2 = React.createRef();
    this.ticklLayer1 = React.createRef();
  }

  componentDidMount () {
  	if (this.props.effect === 'tickle') {
	    tickl(this.ticklWrap.current, [
	    	[this.ticklLayer1.current, .035],
	    	[this.ticklLayer2.current, .07],
	      [this.ticklLayer3.current, .14]
	    ])
	  }
  }

	render () {
		const { className, images, effect, horizontalOffset, sizes, link } = this.props
		if (!images) {
			return false
		}

		return (
		  <Wrapper offset={horizontalOffset} as={link ? Link : 'div'} to={link}>
		  	{effect === 'parallax' && (
		  		<ParallaxWrap>
		  			{images.map((item, index) => {
		  				const offset = (index + 1) * 4
		  				return (
			  				<ParallaxItem
			  					y={[-offset, offset]}
			  					key={item.id}
			  					effect={effect}
			  					index={index}
			  					imageCount={images.length}
			  				>
			  					<Image image={item} sizes={"(max-width: " + mq.mediumBreakpoint + "px) 100vw, 50vw"}/>
			  				</ParallaxItem>
		  				)
		  			})}
		  		</ParallaxWrap>
	  		)}

		  	{effect === 'tickle' && (
					<TickleWrap ref={this.ticklWrap} options={{ max: 20, scale: 1, speed: 3000, reverse: true, reset: true }}>
						{images.map((item, index) => (
		  				<Item key={item.id} effect={effect} ref={this[`ticklLayer${index + 1}`]} index={index} imageCount={images.length}>
		  					<Image image={item} sizes={"(max-width: " + mq.mediumBreakpoint + "px) 100vw, 50vw"}/>
		  				</Item>
	  				))}
					</TickleWrap>
		  	)}

		  	{effect === 'tilt' && (
					<TiltWrap options={{ max: 10, scale: 1, speed: 3000, reverse: true, reset: true }}>
						{images.map((item, index) => (
							<Item key={item.id} effect={effect} index={index} imageCount={images.length}>
								<Image image={item} sizes={"(max-width: " + mq.mediumBreakpoint + "px) 100vw, 50vw"}/>
							</Item>
						))}
					</TiltWrap>
		  	)}
		  </Wrapper>
		)
	}
}

StackedImages.defaultProps = {
	effect: 'parallax',
	horizontalOffset: 0
}

export default StackedImages
